var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"shadow mb-2",staticStyle:{"margin":"0","overflow":"hidden","cursor":"pointer"},attrs:{"no-body":""}},[_c('div',{staticClass:"attachment",style:({
            margin: '0px',
            width: '100%',
            height: _vm.large ? '225px' : '155px',
            '--thumb': ("url(" + (_vm.item.media.urls.thumb) + ")"),
            '--placeholder': ("url(" + (_vm.item.media.urls.placeholder) + ")"),
        })}),_c('b-card-body',{staticStyle:{"padding":"12px 16px 16px 16px"}},[_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('b-avatar',{attrs:{"variant":_vm.Users.utils.resolveAvatarColor( _vm.item.author.id ),"text":_vm.Users.utils.capitalize(("" + (_vm.item.author.firstName)), 1 )}}),_c('div',{staticClass:"d-flex flex-column font-small-3",staticStyle:{"margin-left":"12px"}},[_c('span',{staticClass:"text-capitalize m-0"},[_vm._v(" "+_vm._s(_vm.item.author.firstName)+" "+_vm._s(_vm.item.author.lastName)+" ")]),_c('span',{staticClass:"text-muted font-small-2"},[_vm._v(" "+_vm._s(_vm.utils.lastActivityFormat(_vm.item.timestamp))+" ")])])],1),_c('b-card-text',[_c('div',{staticClass:"d-flex text-ellipsis"},_vm._l((_vm.item.categories),function(category){return _c('b-badge',{staticStyle:{"margin-top":"2px","margin-right":"4px"},attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(category.title)+" ")])}),1)]),_c('b-card-text',{staticClass:"font-weight-bolder",staticStyle:{"display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(_vm.item.header)+" ")]),_c('b-card-text',{staticStyle:{"display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.item.description)}})]),_c('hr'),_c('b-card-text',{staticClass:"text-muted d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"13"}}),_c('span',{staticStyle:{"padding-left":"5px","font-size":"13px"}},[_vm._v(_vm._s(_vm.item.views))])],1),_c('div',{staticClass:"d-flex"},[_c('b-link',{attrs:{"to":{ 
                        name: 'admin-news-update',
                        params: {
                            slug: _vm.item.slug
                        }
                }}},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"EditIcon","size":"14"}})],1),_c('b-link',{staticClass:"ml-1",on:{"click":function($event){return _vm.preferDeleteAction(_vm.item)}}},[_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"TrashIcon","size":"14"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }