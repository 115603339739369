<template>
    <b-card no-body bg-variant="transparent">
        <b-card-header>
            <b-card-title style="font-size: 22px;">
                Новости платформы
            </b-card-title>
            <b-button variant="primary" :to="{ name: 'admin-news-add' }" pill>
                Новая публикация
            </b-button>
        </b-card-header>
        <b-row>
            <b-col md="9" lg="9" cols="12">
                <b-row>
                    <b-col v-for="( item, index ) in items" lg="4" md="4" cols="12">
                        <news-admin-view  :item="item" :large="true" @onDeleteAction="onDeleteAction( index )"  />
                    </b-col>
                </b-row>
            </b-col>
            <b-col md="3" lg="3" colas="12" >
                
                <b-card no-body class="shadow" >
                    <b-card-header>
                        <b-card-title>
                            Популярные
                        </b-card-title>
                    </b-card-header>
                    <b-card-body class="pl-1 pr-1">
                        <b-media
                            v-for="( post, index) in popular"
                            :key="index"
                            no-body
                            :class="index ? 'mt-2 cursor-pointer' : 'cursor-pointer' "
                             @click="$router.push({ 
                                    name: 'admin-news-update',
                                    params: {
                                        slug: post.slug
                                    }
                            })"
                          >
                            <b-media-body>
                              <h6 class="blog-recent-post-title" >
                                <b-link class="text-body-heading" style='font-size: 14px; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;'>
                                  {{ post.header }}
                                </b-link>
                              </h6>
                              <span class="text-muted mb-0">
                                {{ utils.lastActivityFormat(post.timestamp) }}
                              </span>
                            </b-media-body>
                        </b-media>
                    </b-card-body>
                </b-card>
                
                <b-card no-body class="shadow" style="position: sticky; top: 22px;">
                    <b-card-header class="pb-1">
                        <b-card-title>
                            Рубрики
                        </b-card-title>
                    </b-card-header>
                    <b-list-group flush>
                        <b-list-group-item 
                            v-for="category in news.categories" 
                            class="cursor-pointer d-flex justify-content-between" 
                            v-ripple
                            @click="$router.replace({
                                query: {
                                    category: category.id
                                }
                            })"
                        > 
                            <span class="text-body-heading">{{ category.title }}</span>
                            <b-badge>{{ category.news_count }}</b-badge>
                        </b-list-group-item>
                        <b-list-group-item @click="$router.push({ name: 'admin-news-categories' })" class="cursor-pointer text-primary" v-ripple >
                            Управлять
                        </b-list-group-item>
                    </b-list-group>
                </b-card>
                
                
                
                
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    
    import NewsAdminView from "@/components/NewsAdminView"
    import utils from "@/modules/utils/"
    import news from "@/modules/admin/news"

    export default {

        data() {
            return {
                news,
                utils,
                is_loading: true,
                items: [],
                current_page: 0,
                has_more_pages: false,
                last_page: 0,
                total_count: 0,
                categories: [],
                popular: [],
            }
        },
        methods: {
            
            onDeleteAction( index ) {
                this.items.splice(index, 1 );
            },
            
            get() {
                this.$request.get("news/list", this.$route.query ).then( rsp => {
                    this.is_loading = false;
                    this.items = rsp.items;
                    this.total_count = rsp.total_count;
                    this.current_page = rsp.current_page;
                    this.last_page = rsp.last_page;
                    this.categories = rsp.categories;
                    if( rsp.popular.length > 0 ) {
                        this.popular = rsp.popular;
                    }
                });
            }
        },
        components: {
            NewsAdminView
        },
        watch: {
            "$route.query" : function() {
                this.get();
            }
        },
        mounted() {
            this.get();
        }

    }

</script>