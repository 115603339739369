var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":"","bg-variant":"transparent"}},[_c('b-card-header',[_c('b-card-title',{staticStyle:{"font-size":"22px"}},[_vm._v(" Новости платформы ")]),_c('b-button',{attrs:{"variant":"primary","to":{ name: 'admin-news-add' },"pill":""}},[_vm._v(" Новая публикация ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"9","lg":"9","cols":"12"}},[_c('b-row',_vm._l((_vm.items),function(item,index){return _c('b-col',{attrs:{"lg":"4","md":"4","cols":"12"}},[_c('news-admin-view',{attrs:{"item":item,"large":true},on:{"onDeleteAction":function($event){return _vm.onDeleteAction( index )}}})],1)}),1)],1),_c('b-col',{attrs:{"md":"3","lg":"3","colas":"12"}},[_c('b-card',{staticClass:"shadow",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(" Популярные ")])],1),_c('b-card-body',{staticClass:"pl-1 pr-1"},_vm._l((_vm.popular),function(post,index){return _c('b-media',{key:index,class:index ? 'mt-2 cursor-pointer' : 'cursor-pointer',attrs:{"no-body":""},on:{"click":function($event){return _vm.$router.push({ 
                                name: 'admin-news-update',
                                params: {
                                    slug: post.slug
                                }
                        })}}},[_c('b-media-body',[_c('h6',{staticClass:"blog-recent-post-title"},[_c('b-link',{staticClass:"text-body-heading",staticStyle:{"font-size":"14px","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(post.header)+" ")])],1),_c('span',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.utils.lastActivityFormat(post.timestamp))+" ")])])],1)}),1)],1),_c('b-card',{staticClass:"shadow",staticStyle:{"position":"sticky","top":"22px"},attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-1"},[_c('b-card-title',[_vm._v(" Рубрики ")])],1),_c('b-list-group',{attrs:{"flush":""}},[_vm._l((_vm.news.categories),function(category){return _c('b-list-group-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"cursor-pointer d-flex justify-content-between",on:{"click":function($event){return _vm.$router.replace({
                            query: {
                                category: category.id
                            }
                        })}}},[_c('span',{staticClass:"text-body-heading"},[_vm._v(_vm._s(category.title))]),_c('b-badge',[_vm._v(_vm._s(category.news_count))])],1)}),_c('b-list-group-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"cursor-pointer text-primary",on:{"click":function($event){return _vm.$router.push({ name: 'admin-news-categories' })}}},[_vm._v(" Управлять ")])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }