<template>
    <b-card no-body style=" margin: 0; overflow: hidden; cursor: pointer;" class="shadow mb-2">
        <div class="attachment"
            :style="{
                margin: '0px',
                width: '100%',
                height: large ? '225px' : '155px',
                '--thumb': `url(${item.media.urls.thumb})`,
                '--placeholder': `url(${item.media.urls.placeholder})`,
            }"
        >

        </div>
        <b-card-body  style='padding: 12px 16px 16px 16px;'>
            
            <div class="d-flex align-items-center mb-1">
                <b-avatar
                    :variant="Users.utils.resolveAvatarColor( item.author.id )"
                    :text="Users.utils.capitalize(`${item.author.firstName}`, 1 )"
                />
                <div class="d-flex flex-column font-small-3" style="margin-left: 12px;">
                    <span class="text-capitalize m-0">
                        {{ item.author.firstName }} {{ item.author.lastName }}
                    </span>
                    <span class="text-muted font-small-2">
                        {{ utils.lastActivityFormat(item.timestamp) }}
                    </span>
                </div>
            </div>
            
              
            <b-card-text>
                <div class='d-flex text-ellipsis'>
                    <b-badge v-for='category in item.categories' variant="secondary" style='margin-top: 2px; margin-right: 4px;'> 
                        {{ category.title }}
                    </b-badge>
                </div>
            </b-card-text>
            
            <b-card-text class="font-weight-bolder" style="display: -webkit-box; -webkit-line-clamp: 2;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                {{ item.header }}
            </b-card-text>
            
           
            <b-card-text style="display: -webkit-box; -webkit-line-clamp: 3;  -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;">
                <span v-html="item.description" />
            </b-card-text>
          
            <hr>
            
            <b-card-text class="text-muted d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <feather-icon icon="EyeIcon" size="13" />
                    <span style="padding-left: 5px; font-size: 13px;">{{ item.views }}</span>
                </div>
                <div class="d-flex">
                    <b-link :to="{ 
                            name: 'admin-news-update',
                            params: {
                                slug: item.slug
                            }
                    }" >
                        <feather-icon icon="EditIcon" class="text-primary" size="14" />
                    </b-link>
                    <b-link @click="preferDeleteAction(item)" class="ml-1">
                        <feather-icon icon="TrashIcon" class="text-danger" size="14" />
                    </b-link>
                </div>
                
            </b-card-text>
        </b-card-body>
    </b-card>
</template>

<script>
    
    import Users from "@/modules/admin/users"
    import utils from "@/modules/utils/"

    export default {

        data() {
            return {
                utils,
                Users
            }
        },
        props: {
            item: Object,
            large: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            preferDeleteAction( item ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить публикацию?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("news/delete", {
                            id: item.id
                        }).then( rsp => {
                            this.$emit("onDeleteAction");
                            this.$swal({
                                icon: 'success',
                                title: item.title,
                                text: 'Публикация успешно удалена',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
                
            },
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>